<!--
 * @Author: gaojingran
 * @Date: 2021-04-19 09:37:50
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-08-05 10:20:04
 * @Description: 报价单
-->
<style lang="less" scoped>
.quotation_link {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.quotation {
  .quotation-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .logo {
      width: 84px;
      height: 42px;
      background-image: url('~@/assets/image/pactera-logo.png');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }

    .title {
      font-size: 0;
      .date,
      .text {
        display: inline-block;
        vertical-align: middle;
      }
      .text {
        font-size: @font_size_6;
        color: @text_color_1;
        font-weight: bold;
      }
      .date {
        padding-left: 5px;
        font-size: @font_size_2;
        color: @text_color_2;
      }
    }
  }

  .info-item {
    display: flex;
    .label {
      flex: 0 0 80px;
      width: 80px;
      text-align: right;
      font-size: @font_size_2;
      color: @text_color_1;
    }
    .desc {
      flex: 1;
      width: 0;
      margin-left: 10px;
      font-size: @font_size_2;
      color: @text_color_2;
      white-space: pre-wrap;
      word-wrap: break-word;
    }
  }

  .quotation-type {
    display: flex;
    align-items: center;
  }
}

.actually {
  float: right;
  margin-top: -28px;
}

.actually input {
  margin-left: 10px;
  width: 120px;
}

.textarea {
  resize: none;
  height: 100px;
  padding: 8px 11px;
}

.display-actual {
  display: flex;
  align-items: center;
  height: 32px;
  margin-top: 10px;
  float: right;
}

.againModifyPrice {
  margin-left: 10px;
  width: 120px;
}

.btn-modify {
  margin-left: 10px;
  width: 21px;
  height: 21px;
  background-image: url('~@/assets/image/confirm.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}

.btn-cancel {
  margin-left: 10px;
  width: 21px;
  height: 21px;
  background-image: url('~@/assets/image/cancel.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}

.btn-edit {
  margin-left: 10px;
  width: 20px;
  height: 20px;
  background-image: url('~@/assets/image/edit.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}

.btn-accept {
  margin-left: 10px;
  width: 23px;
  height: 23px;
  background-image: url('~@/assets/image/check-circle.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}

.btn-refuse {
  margin-left: 10px;
  width: 23px;
  height: 23px;
  background-image: url('~@/assets/image/close-circle.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}

.extra {
  display: flex;
}
</style>

<template>
  <NeoDrawerLayout :showFooter="!isPdf" :useOriginalScroll="isPdf">
    <a-spin :spinning="loading" style="height: 100%">
      <div class="quotation">
        <div class="quotation-title">
          <div class="logo"></div>
          <div class="title">
            <span class="text">{{ $t('orderInfo.quote') }}</span>
            <span class="date" v-if="reportInfo.quoteDate">{{ reportInfo.quoteDate | dateFormat }}</span>
          </div>
          <div class="extra">
            <a-button v-if="showDownloadPDF" class="neo-btn-primary" @click="downpdf">
              {{ $t('payment.donwload_order') }}
            </a-button>

            <!-- 上传报价单 -->
            <NeoUploadButton
              v-if="showUpload"
              :title="$t('payment.upload_order')"
              v-model="quotationPDF"
              :showNote="false"
              :showFileList="false"
              :neoStyle="false"
              :showIcon="showIcon"
              :maxLength="100"
              :maxSize="50"
              accept=".pdf,.zip,.xls,.xlsx"
              style="margin-left: 10px; width: 80px; margin-right: 39px"
              @input="uppdf"
              :loading="!showIcon"
            />

            <template v-if="!isPdf">
              <a-button
                type="primary"
                :disabled="reportInfo.status === 1"
                :loading="pdfLoading"
                @click="handleExport"
                style="margin-left: 10px"
              >
                {{ $t('export') }}
              </a-button>
            </template>

            <NeoTag v-if="isPdf && ids.length < 2" shape type="warning">{{
              localeDict.orderStatus[Math.min(9, reportInfo.status)] | defined
            }}</NeoTag>
          </div>
        </div>
        <!-- 报价单详情 -->
        <!-- <NeoTitle class="mb-3" text="123123" /> -->
        <NeoTitle class="mb-3" :text="$t('orderInfo.quote_info')" />
        <a-row :gutter="20">
          <a-col :span="24" class="info-item mb-3" v-if="ids.length < 2">
            <span class="label">{{ $t('orderInfo.quote_title') }}:</span>
            <span class="desc">{{ reportInfo.code | defined }} / {{ reportInfo.name | defined }}</span>
          </a-col>
          <a-col :span="12" class="info-item mb-3">
            <span class="label">{{ $t('orderInfo.quote_customer') }}:</span>
            <span class="desc">{{ reportInfo.cusAgencyName | defined }}</span>
          </a-col>
          <a-col :span="12" class="info-item mb-3">
            <span class="label">{{ $t('orderInfo.quote_provider') }}:</span>
            <span class="desc">{{ reportInfo.comAgencyName | defined }}</span>
          </a-col>
          <a-col :span="12" class="info-item mb-3">
            <span class="label">{{ $t('orderInfo.quote_address') }}:</span>
            <span class="desc">{{ reportInfo.cusAgencyAddress | defined }}</span>
          </a-col>
          <a-col :span="12" class="info-item mb-3">
            <span class="label">{{ $t('orderInfo.quote_address') }}:</span>
            <span class="desc">{{ reportInfo.comAgencyAddress | defined }}</span>
          </a-col>
          <a-col :span="12" class="info-item mb-3" v-if="ids.length < 2">
            <span class="label">{{ $t('orderInfo.quote_contact') }}:</span>
            <span class="desc">
              {{ reportInfo.cusManager | defined }} ({{ reportInfo.cusManagerEmail | defined }})
            </span>
          </a-col>
          <a-col :span="12" class="info-item mb-3" v-if="ids.length < 2">
            <span class="label">{{ $t('orderInfo.quote_contact') }}:</span>
            <span class="desc">
              {{ reportInfo.comManager | defined }} ({{ reportInfo.comManagerEmail | defined }})
            </span>
          </a-col>
        </a-row>
        <!-- 普通导出时候的表格 -->
        <a-table
          class="head-bg no-br"
          size="middle"
          rowKey="id"
          v-if="ids.length < 2"
          :data-source="dataSource"
          :pagination="false"
          :bordered="true"
        >
          <a-table-column
            key="serviceType"
            :width="getWidth(100)"
            :ellipsis="true"
            :title="$t('orderInfo.table_serviceType')"
          >
            <template slot-scope="text">
              <a-tooltip placement="topLeft" :title="text.serviceLabel">
                <span>{{ text.serviceLabel }}</span>
              </a-tooltip>
            </template>
          </a-table-column>

          <a-table-column
            key="sourceCode"
            :width="getWidth('16.6%')"
            :ellipsis="true"
            :title="$t('orderInfo.table_source')"
          >
            <!-- eslint-disable-next-line vue/no-unused-vars -->
            <template slot-scope="text">
              <span :title="$store.getters['app/getLangNameByCode'](text.sourceCode) | defined">{{
                $store.getters['app/getLangNameByCode'](text.sourceCode) | defined
              }}</span>
            </template>
          </a-table-column>

          <a-table-column
            key="targetCode"
            :width="getWidth('16.6%')"
            :ellipsis="true"
            :title="$t('orderInfo.table_target')"
          >
            <template slot-scope="text">
              <span :title="$store.getters['app/getLangNameByCode'](text.targetCode) | defined">
                {{ $store.getters['app/getLangNameByCode'](text.targetCode) | defined }}</span
              >
            </template>
          </a-table-column>

          <a-table-column
            key="price"
            align="right"
            :ellipsis="true"
            :width="getWidth(130)"
            :title="$t('orderInfo.table_price')"
          >
            <template slot-scope="text">
              <!-- 当等待报价 & 没有selectivePriceList时显示尚未报价 -->
              <template
                v-if="
                  (!$is.Defined(text.selectivePriceList) || !text.selectivePriceList.length) &&
                  info.status === 1 &&
                  noProjManText(text)
                "
              >
                <!-- 尚未报价 -->
                <span>
                  --
                  <a-tooltip class="warning" placement="top" v-if="noProjManText(text)">
                    <template v-slot:title>
                      <span class="quotation_link" @click="handleAddPrice(text)" v-if="info.isOrderSpm === 1">
                        {{ $t('orderInfo.quote_no_price') }}
                      </span>
                      <span v-else>{{ $t('orderInfo.quote_no_price') }}</span>
                    </template>
                    <a-icon type="exclamation-circle" theme="filled" />
                  </a-tooltip>
                </span>
              </template>
              <template v-else>
                <!-- 供应商可在等待报价阶段修改单价 -->
                <template v-if="$role('order_orderInfo_createQuote') && info.status === 1 && noProjManText(text)">
                  <a-select
                    v-model="text.priceType"
                    :placeholder="$t('orderInfo.quote_init_price_holder')"
                    size="small"
                    style="display: block"
                  >
                    <a-select-option v-for="item in text.selectivePriceList" :key="item.id" :value="item.id">
                      <span v-if="currencyUnit()">{{ currencyUnit()[1] }}</span>
                      <span>{{ item.price }}</span> /
                      <span>{{ $store.getters['app/getDictLabel']('SALE_UNIT', item.unit) | defined }}</span>
                    </a-select-option>
                  </a-select>
                </template>
                <template v-else>
                  <template v-if="text.serviceKind === 3">
                    <span v-if="currencyUnit()">{{ currencyUnit()[1] }}</span>
                    <span>{{ text.price }}</span>
                  </template>
                  <template v-else>
                    <span v-if="currencyUnit()">{{ currencyUnit()[1] }}</span>
                    <span>{{ text.price }}</span> /
                    <span>{{ $store.getters['app/getDictLabel']('SALE_UNIT', text.unit) | defined }}</span>
                  </template>
                </template>
              </template>
            </template>
          </a-table-column>

          <a-table-column
            key="amount"
            align="right"
            :ellipsis="true"
            :width="getWidth(80)"
            :title="$t('orderInfo.table_number')"
          >
            <template slot-scope="text">
              <span>{{ text.amount }}</span>
            </template>
          </a-table-column>

          <a-table-column
            key="totalPrice"
            align="right"
            :ellipsis="true"
            :width="getWidth(90)"
            :title="$t('orderInfo.table_totalPrice')"
          >
            <template slot-scope="text, _, index">
              <span v-if="!$is.Defined(text.priceType) && info.status === 1 && noProjManText(text)">--</span>
              <span v-else>
                <div class="_flex" style="justify-content: flex-end">
                  <!-- <span class="_mr-2" v-if="currencyUnit()">{{ currencyUnit()[1] }}</span> -->
                  <template v-if="noProjManText(text)">
                    <QuotationItem
                      :isPdf="isPdf"
                      :text="getPriceByAmount(index)"
                      :showUnit="currencyUnit()"
                      :currencyUnit="currencyUnit()[1]"
                    />
                  </template>
                  <template v-else>
                    <QuotationItem
                      :isPdf="isPdf"
                      :text="proAdminCost"
                      :showUnit="currencyUnit()"
                      :currencyUnit="currencyUnit()[1]"
                    />
                  </template>
                </div>
              </span>
            </template>
          </a-table-column>

          <!-- 删除单条报价 & 仅可删除服务类型2开头的 -->
          <a-table-column
            v-if="$role('order_orderInfo_createQuote') && info.status === 1 && !isPdf"
            key="action"
            align="right"
            :width="80"
            :title="$t('action')"
          >
            <template slot-scope="text, _, index">
              <a-popconfirm
                v-if="text.serviceKind === 2"
                placement="left"
                :title="$t('prompt.info', { handle: $t('delete') })"
                :ok-text="$t('confirm')"
                :cancel-text="$t('cancel')"
                @confirm="
                  () => {
                    handeDeletePriceItem(index)
                  }
                "
              >
                <a>{{ $t('delete') }}</a>
              </a-popconfirm>
            </template>
          </a-table-column>

          <template slot="footer">
            <a-row type="flex" align="middle" justify="space-between">
              <div>
                <span class="c-1">{{ $t('orderInfo.table_footer_label') }}:</span>
                <span class="c-2 pl-1">{{ $t('orderInfo.table_footer_desc') }}</span>
              </div>
              <div>
                <span class="fs-3 c-1 bold">{{ $t('orderInfo.table_footer_priceTotal') }}:</span>
                <span class="fs-3 primary pl-1">
                  <span v-if="currencyUnit()">{{ currencyUnit()[1] }}</span>
                  <span>{{ getTableTotalPrice2() | countFormat }}</span>
                  <!--报价总计-->
                </span>
              </div>
            </a-row>
          </template>
        </a-table>

        <!-- 批量导出时候的表格 -->
        <a-table
          class="head-bg no-br"
          size="middle"
          rowKey="id"
          v-if="ids.length >= 2"
          :data-source="batchDataSource"
          :pagination="false"
          :bordered="true"
        >
          <a-table-column key="code" :ellipsis="true" :title="$t('orderInfo.table_order_num')">
            <template slot-scope="text">
              <span>{{ text.code }}</span>
            </template>
          </a-table-column>

          <a-table-column key="name" :ellipsis="true" :title="$t('orderInfo.table_order_name')">
            <template slot-scope="text">
              <span class="line-feed">{{ text.name }}</span>
            </template>
          </a-table-column>

          <a-table-column key="quoteDate" :ellipsis="true" :title="$t('orderInfo.table_order_price_date')">
            <template slot-scope="text">
              <span>{{ moment(text.quoteDate).format($t('dateFormat.b')) | defined }}</span>
            </template>
          </a-table-column>

          <a-table-column key="status" :ellipsis="true" :title="$t('orderInfo.table_order_status')">
            <!-- eslint-disable-next-line vue/no-unused-vars -->
            <template slot-scope="text">
              <span :class="text.status >= 9 ? 'success' : ''">{{
                localeDict.orderStatus[Math.min(9, text.status)] | defined
              }}</span>
            </template>
          </a-table-column>
          <!-- 下单方经理 -->
          <a-table-column key="cusManager" :ellipsis="true" :title="$t('payment.customer_contacts')">
            <template slot-scope="text">
              <span>{{ text.cusManager | defined }}</span>
            </template>
          </a-table-column>
          <!-- 供应商经理 -->
          <a-table-column key="comManager" :ellipsis="true" :title="$t('payment.provider_contacts')">
            <template slot-scope="text">
              <span>{{ text.comManager | defined }}</span>
            </template>
          </a-table-column>

          <a-table-column
            key="totalPrice"
            align="right"
            :ellipsis="true"
            :width="100"
            :title="$t('orderInfo.table_order_total_price')"
          >
            <template slot-scope="text">
              <span>
                <span v-if="currencyUnit(text.currency)">{{ currencyUnit(text.currency)[1] }}</span>
                {{ text.quotePrice | countFormat }}
              </span>
            </template>
          </a-table-column>

          <template slot="footer">
            <a-row type="flex" align="middle" justify="space-between">
              <div v-html="$t('orderInfo.table_order_footer_count', { number: batchDataSource.length })"></div>
              <div>
                <span class="fs-3 c-1 bold">{{ $t('orderInfo.table_footer_priceTotal') }}:</span>
                <span class="fs-3 primary pl-1">
                  <span v-if="currencyUnit(reportInfo.currency)">{{ currencyUnit(reportInfo.currency)[1] }}</span>
                  {{ getBatchDataSourceTotalPrice() | countFormat }}
                </span>
              </div>
            </a-row>
          </template>
        </a-table>
        <!-- 新增报价项 -->
        <div class="quotation-type mt-3" v-if="$role('order_orderInfo_addQuoteOption') && !isPdf && info.status === 1">
          <!-- 新增报价项 -->
          <a-button class="neo-btn-primary" @click="handleQuoteOption">
            {{ $t('orderInfo.btn_quote_newOption') }}
          </a-button>
          <!-- 包含项目管理费 -->
          <div>
            <a-checkbox style="margin: 0 10px 0 20px" v-model="containsProjectManageCost"> </a-checkbox>
            {{ $t('newOrder.project_manage_cost_head') }}
            <a-input-number
              style="width: 80px"
              :value="projectManageRatio"
              @change="projectManageRatioInput($event, 'a-input-number-x')"
              :disabled="!containsProjectManageCost"
              :min="0"
              :max="100"
              :precision="0"
              :step="1"
            />
            {{ $t('newOrder.project_manage_cost_tail') }}
          </div>
        </div>

        <!-- 供应商 在双方结算前 皆可修改 -->
        <div v-if="SupStatus1" class="actually">
          <span>{{ $t('payment.actual_order_price') }}：{{ currencyUnit(reportInfo.currency)[1] }}</span>
          <a-input
            :placeholder="$t('payment.actual_price')"
            v-model="quoteRealPrice"
            :value="quoteRealPrice"
            @change="settlementPriceChange1($event)"
          />
        </div>

        <!--如果供应商从来没设置过实际金额，就不要展示了-->
        <!-- 客户端 -- 报价阶段 -- 批准实际实际订单金额 -->
        <!-- numeral(text.quotePrice).format('0.[00]')  numeral(price * amount).format('0.[00]') -->
        <div v-if="reportInfo.quotePendingPrice !== null || reportInfo.quoteRealPrice !== null">
          <div class="display-actual" v-if="custWatchPrice || SupWatchPrice">
            {{ $t('payment.actual_order_price') }}：{{ currencyUnit(reportInfo.currency)[1] }}
            <span v-if="reportInfo.quoteNeedApprove == 1">{{
              reportInfo.quotePendingPrice !== null ? reportInfo.quotePendingPrice : reportInfo.quoteRealPrice
            }}</span>
            <span v-else>{{ reportInfo.quoteRealPrice }}</span>
            <!-- 有个坑，上面不能直接reportInfo.quoteRealPrice这样判断 -->
          </div>
        </div>

        <!-- 生产中 供应商再次修改实际报价 -->
        <div class="display-actual" v-if="supCanModifyPrice">
          {{ $t('payment.actual_order_price') }}：{{ currencyUnit(reportInfo.currency)[1] }}
          <span v-if="!isModify">{{
            actuallyPrice !== null ? actuallyPrice : getTableTotalPrice2() | countFormat
          }}</span>
          <a-input
            class="againModifyPrice"
            :placeholder="$t('payment.modify_price')"
            v-model="againModifyPrice"
            v-if="isModify"
            :value="againModifyPrice"
            @change="settlementPriceChange($event)"
          />

          <div class="btn-edit" v-if="!isModify" @click="showIsModify"></div>
          <div class="btn-modify" v-if="isModify" @click="againModify"></div>
          <div class="btn-cancel" v-if="isModify" @click="cancelModify"></div>
        </div>

        <!-- 生产中 两种情况 供应商没改价格、改了价格 客户端想要拒绝更新的报价 -->
        <!-- 如果供应商从来没设置过金额，就直接不展示 -->
        <div v-if="reportInfo.quotePendingPrice !== null || reportInfo.quoteRealPrice !== null">
          <div class="display-actual" v-if="custCanModifyPrice">
            {{ $t('payment.actual_order_price') }}：{{ currencyUnit(reportInfo.currency)[1] }}
            <span>{{ actuallyPrice !== null ? actuallyPrice : getTableTotalPrice2() | countFormat }}</span>
            <div class="btn-accept" @click="accept" v-if="showConfirmBtn"></div>
            <div class="btn-refuse" @click="refuse" v-if="showConfirmBtn"></div>
          </div>
        </div>

        <!-- 供应商端 报价说明 -->
        <div v-if="$role('order_orderInfo_addQuoteOption') && !isPdf && info.status === 1" style="margin-top: 35px">
          <NeoTitle class="mt-3" style="margin-top: 0" :text="$t('payment.quotation_explain')" />
          <div style="margin-top: 10px">
            <a-textarea class="textarea" maxlength="300" v-model="quoteNote" placeholder="少于300个字..." />
          </div>
        </div>

        <div style="margin-top: 35px" v-else>
          <NeoTitle
            class="mt-3"
            style="margin-top: 0; margin-bottom: 10px"
            :text="$t('payment.quotation_explain')"
            v-if="reportInfo.quoteNote"
          />
          <pre
            style="padding-left: 12px"
            class="m0 text-wrap"
            v-if="reportInfo.quoteNote"
            v-html="reportInfo.quoteNote"
          />
          <!-- <span style="padding-left: 12px">{{ reportInfo.quoteNote }}</span> -->
        </div>

        <!-- wwc 字数明细 非翻译和创译就不显示-->
        <template v-if="info.serviceValue == 1">
          <NeoTitle class="mt-3" :text="$t('orderInfo.quote_wwc_detail')" />
          <!-- 单个订单 -->
          <template v-if="ids.length < 2">
            <a-table
              class="head-bg mt-3"
              size="middle"
              rowKey="id"
              :scroll="!isPdf ? { x: 1200 } : {}"
              :data-source="wwcDataSource"
              :pagination="false"
              :bordered="true"
            >
              <a-table-column
                key="targetCode"
                align="center"
                :fixed="!isPdf ? 'left' : null"
                :ellipsis="false"
                :width="180"
                :title="$t('orderInfo.table_lang')"
              >
                <template slot-scope="text">
                  <span>{{ $store.getters['app/getLangNameByCode'](text.targetCode) }}</span>
                </template>
              </a-table-column>

              <a-table-column-group>
                <span slot="title">{{ $t('orderInfo.table_matching') }}</span>
                <a-table-column v-for="k in wwcColumn" :key="k" align="center" :title="k">
                  <template slot-scope="text">
                    <span>{{ text[k] }}</span>
                  </template>
                </a-table-column>
              </a-table-column-group>

              <a-table-column
                key="wwc"
                :fixed="!isPdf ? 'right' : null"
                :ellipsis="false"
                align="center"
                :width="100"
                :title="$t('orderInfo.table_wwc')"
              >
                <template slot-scope="text, _, index">
                  <span>{{ getWwcCount(index) }}</span>
                </template>
              </a-table-column>
            </a-table>
          </template>
          <!-- 多个订单 区分版本 -->
          <template v-else>
            <a-table
              v-if="wwcDataSourceVersionA.length"
              class="head-bg mt-3"
              size="middle"
              rowKey="id"
              :scroll="!isPdf ? { x: 1200 } : {}"
              :data-source="wwcDataSourceVersionA"
              :pagination="false"
              :bordered="true"
            >
              <a-table-column
                key="orderCode"
                align="center"
                :fixed="!isPdf ? 'left' : null"
                :ellipsis="false"
                :width="120"
                :title="$t('orderInfo.table_order_num')"
                v-if="ids.length >= 2"
              >
                <template slot-scope="text">
                  <span>{{ text.orderCode }}</span>
                </template>
              </a-table-column>

              <a-table-column
                key="targetCode"
                align="center"
                :fixed="!isPdf ? 'left' : null"
                :ellipsis="false"
                :title="$t('orderInfo.table_lang_double')"
                v-if="ids.length >= 2"
              >
                <template slot-scope="text">
                  <span>{{
                    `${$store.getters['app/getLangNameByCode'](text.sourceCode)}>${$store.getters[
                      'app/getLangNameByCode'
                    ](text.targetCode)}`
                  }}</span>
                </template>
              </a-table-column>

              <a-table-column-group>
                <span slot="title">{{ $t('orderInfo.table_matching') }}</span>
                <a-table-column v-for="k in getWwcTableRangeByProduceVersion(1)" :key="k" align="center" :title="k">
                  <template slot-scope="text">
                    <span>{{ text[k] }}</span>
                  </template>
                </a-table-column>
              </a-table-column-group>

              <a-table-column
                key="wwc"
                :fixed="!isPdf ? 'right' : null"
                :ellipsis="false"
                align="center"
                :width="100"
                :title="$t('orderInfo.table_wwc')"
              >
                <template slot-scope="text, _, index">
                  <span>{{ getBatchWwcCount(1, wwcDataSourceDiscountA, index) }}</span>
                </template>
              </a-table-column>
            </a-table>

            <a-table
              v-if="wwcDataSourceVersionB.length"
              class="head-bg mt-3"
              size="middle"
              rowKey="id"
              :scroll="!isPdf ? { x: 1200 } : {}"
              :data-source="wwcDataSourceVersionB"
              :pagination="false"
              :bordered="true"
            >
              <a-table-column
                key="orderCode"
                align="center"
                :fixed="!isPdf ? 'left' : null"
                :ellipsis="false"
                :width="140"
                :title="$t('orderInfo.table_order_num')"
                v-if="ids.length >= 2"
              >
                <template slot-scope="text">
                  <span>{{ text.orderCode }}</span>
                </template>
              </a-table-column>

              <a-table-column
                key="targetCode"
                align="center"
                :fixed="!isPdf ? 'left' : null"
                :ellipsis="false"
                :title="$t('orderInfo.table_lang_double')"
                v-if="ids.length >= 2"
              >
                <template slot-scope="text">
                  <span>{{
                    `${$store.getters['app/getLangNameByCode'](text.sourceCode)}>${$store.getters[
                      'app/getLangNameByCode'
                    ](text.targetCode)}`
                  }}</span>
                </template>
              </a-table-column>

              <a-table-column-group>
                <span slot="title">{{ $t('orderInfo.table_matching') }}</span>
                <a-table-column v-for="k in getWwcTableRangeByProduceVersion(2)" :key="k" align="center" :title="k">
                  <template slot-scope="text">
                    <span>{{ text[k] }}</span>
                  </template>
                </a-table-column>
              </a-table-column-group>

              <a-table-column
                key="wwc"
                :fixed="!isPdf ? 'right' : null"
                :ellipsis="false"
                align="center"
                :width="100"
                :title="$t('orderInfo.table_wwc')"
              >
                <template slot-scope="text, _, index">
                  <span>{{ getBatchWwcCount(2, wwcDataSourceDiscountB, index) }}</span>
                </template>
              </a-table-column>
            </a-table>
          </template>
        </template>
      </div>
      <!-- 批准报价 -->
      <QuoteApproveModal
        :reportInfo="reportInfo"
        :info="info"
        :visible.sync="approveVisible"
        @closeDrawer="closeDrawer"
      />
      <!-- 新增报价选项 -->
      <AddQuoteOptionModal
        :orderInfo="info"
        :info="reportInfo"
        :selectedServiceType="selectedServiceType"
        :visible.sync="quoteOptionVisible"
        @addItem="handleAddPriceItem"
      />
      <!-- 添加价格 -->
      <AddPriceModal :defaultInfo="priceFormData" :visible.sync="priceVisible" @refresh="handleRefreshSelectItem" />
    </a-spin>
    <!-- footer -->
    <template v-if="!isPdf" v-slot:footer>
      <a-space :size="10" class="fl-r">
        <!-- 创建报价 -> 等待报价 -->
        <a-button
          type="primary"
          v-if="$role('order_orderInfo_createQuote') && info.status === 1"
          :disabled="createQuoteBtnDisable"
          @click="handleCreateQuote"
        >
          {{ $t('orderInfo.btn_submit_quote') }}
        </a-button>
        <!-- 拒绝 -> 待确认报价 -->
        <a-button
          class="neo-btn-primary"
          v-if="$role('order_orderInfo_quoteRefuse') && info.status === 2"
          @click="handleRefuse"
        >
          {{ $t('refuse') }}
        </a-button>
        <!-- 退回重新报价 -> 待确认报价 -->
        <a-button
          class="neo-btn-primary"
          v-if="$role('order_orderInfo_quoteRefuse') && info.status === 2"
          @click="handleReject"
        >
          {{ $t('orderInfo.btn_quote_reject') }}
        </a-button>
        <!-- 批准报价 -> 待确认报价 -->
        <a-button
          type="primary"
          v-if="$role('order_orderInfo_quoteApprove') && info.status === 2"
          @click="handleApprove"
        >
          {{ $t('orderInfo.btn_quote_approve') }}
        </a-button>
      </a-space>
    </template>
  </NeoDrawerLayout>
</template>

<script>
import Cookies from 'js-cookie'
import numeral from 'numeral'
import { uuid, downloadBlob, downloadUrl } from '@/utils/utils'
import { Icon } from 'ant-design-vue'
import NeoTag from '@/components/common/NeoTag'
import QuoteApproveModal from './ApproveModal'
import AddQuoteOptionModal from './AddQuoteOptionModal'
import NeoUploadButton from '@/components/upload/NeoUploadButton'
import AddPriceModal from './AddPriceModal'
import moment from 'moment'
import QuotationItem from './QuotationItem'
const { localeDict, storageKey, wordReportRangeColumn } = window.$g
import floatObj from '@/utils/ASMD.js'

export default {
  name: 'Quatation',
  components: {
    NeoTag,
    QuoteApproveModal,
    AddQuoteOptionModal,
    AddPriceModal,
    QuotationItem,
    NeoUploadButton,
  },
  props: {
    // 订单详情
    info: {
      type: Object,
      default: () => ({}),
    },
    // 展示方式是否为pdf（用于pdf导出）
    isPdf: {
      type: Boolean,
      default: false,
    },
    // 批量导出的id
    ids: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      localeDict,
      pdfLoading: false,
      loading: false,
      // 报价单详情
      reportInfo: {},
      dataSource: [],
      batchDataSource: [],
      containsProjectManageCost: false,
      projectManageRatio: 0,
      // wwc
      wwcColumn: [],
      wwcDataSource: [],
      wwcDataSourceDiscount: [],

      // 批量版本wwc根据produceVersion
      wwcDataSourceVersionA: [],
      wwcDataSourceDiscountA: [],
      wwcDataSourceVersionB: [],
      wwcDataSourceDiscountB: [],

      // 批准报价
      approveVisible: false,
      // 新增报价选项
      quoteOptionVisible: false,
      // price表单数据
      priceFormData: {},
      // price visible
      priceVisible: false,
      moment,
      quoteRealPrice: '', // 新增实际金额
      quoteNote: '', // 新增报价说明

      actuallyPrice: '', // 实际价格，默认以quoteRealPrice为准，生产中的状态会有所更改
      againModifyPrice: '', // 生产中再次修改的价格
      isModify: false, // 是否显示在此修改的输入框

      showConfirmBtn: false, // 客户端是否显示勾和叉按钮 客户批准报价->供应商未改价格->不显示确认拒绝按钮
      quotationPDF: '', // 报价上传的pdf
      showDownloadPDF: false, // 是否显示下载pdf按钮

      scoreUpp1: '',
      showIcon: true,
    }
  },
  watch: {
    dataSource: {
      handler: function (arr) {
        arr?.forEach((item) => {
          if (item.selectivePriceList?.length === 1) {
            // 当长度只有1时，自动选择第一个值
            item.priceType = item.selectivePriceList[0].id
          }
        })
      },
      deep: true, //深度监听
    },
    proAdminCost(price) {
      console.log(`price`, price)
      const target = this.dataSource.find((item) => item.key === 'project_manage_text')
      target && (target.price = price)
      console.log(`target`, target)
    },
    containsProjectManageCost(v) {
      if (v) {
        //  添加项目管理行
        if (this.dataSource.every((item) => !item.key)) {
          this.dataSource.push(this.projectAdmin)
        }
      } else {
        // 删去项目管理行
        this.dataSource.forEach((item, idx) => {
          if (item.key === 'project_manage_text') {
            this.dataSource.splice(idx, 1)
          }
        })
      }
      this.projectManageRatio = 0
    },
  },
  computed: {
    selfs() {
      return this
    },
    // 当price存在null情况就不能创建报价
    createQuoteBtnDisable() {
      const flag = this.dataSource
        .filter((v) => v.key !== 'project_manage_text')
        .find((v) => !this.$is.Defined(v.selectivePriceList) || !v.selectivePriceList.length || !v.priceType)
      if (flag || !this.dataSource.length) {
        return true
      }
      return false
    },

    // 报价单中已选的服务类型
    selectedServiceType() {
      const ids = this.dataSource.map((v) => v.serviceType)
      return [...new Set(ids)]
    },
    restRadio() {
      return numeral(this.projectManageRatio).divide(100).value()
    },
    proAdminCost() {
      const proAdminCost = floatObj.multiply(this.getTableTotalPrice(), this.restRadio)
      console.log('this.restRadio :>> ', this.restRadio)
      console.error('proAdminCost :>> ', proAdminCost)
      const proAdminCost_length = String(proAdminCost).split('.')[1]?.length
      console.error('proAdminCost_length :>> ', proAdminCost_length)
      return proAdminCost
    },
    projectAdmin() {
      return {
        key: 'project_manage_text',
        amount: 1,
        currency: '1',
        // status: 999,
        id: Math.random().toString(36).substr(-8),
        orderId: '1435164939401863170',
        price: this.proAdminCost,
        priceType: 0,
        remark: null,
        selectivePriceList: [404],
        serviceKind: 3,
        serviceLabel: this.$t('newOrder.project_manage_text'),
        serviceType: '3',
        sourceCode: null,
        targetCode: null,
        unit: null,
      }
    },

    showUpload() {
      return this.$g.isSup && [0, 1, 2, 4, 5, 6, 7, 8, 9, 11].includes(this.info?.status)
    },

    // 客户端 只能查看 创建报价和结算完之后
    custWatchPrice() {
      return this.$g.isCust && [1, 2, 3, 10, 12].includes(this.info?.status)
    },

    // 供应商 创建报价阶段就被客户拒绝 也能查看一下实际金额
    SupWatchPrice() {
      return this.$g.isSup && [3, 10, 12, 13].includes(this.info?.status)
    },

    // 供应商 创建报价状态
    SupStatus1() {
      return this.$g.isSup && [1].includes(this.info?.status)
    },

    // 供应商 可修改价格的阶段
    supCanModifyPrice() {
      return this.$g.isSup && [2, 4, 5, 6, 7, 8, 9, 11].includes(this.info?.status)
    },

    // 客户端 可接受拒绝实际价格的阶段
    custCanModifyPrice() {
      return this.$g.isCust && [4, 5, 6, 7, 8, 9, 11].includes(this.info?.status)
    },
  },
  mounted() {
    if ('id' in this.info) {
      this.getStoneReportInfo(this.info.id)
      this.showDownloadBtn(this.info.id)
    } else {
      if (this.ids.length === 1) {
        this.getStoneReportInfo(this.ids[0])
        this.showDownloadBtn(this.ids[0])
      } else {
        this.getBatchReportInfo(this.ids)
      }
    }
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    projectManageRatioInput(e, ref) {
      console.log('e :>> ', e)
      // const dom = this.$g.findDom(this.$refs[ref].$el, 'input')
      // console.log('dom :>> ', dom)
      // const value = dom.value
      // console.log('value :>> ', value)
      // console.log('val :>> ', val)

      if (!/^([0-9]{1,2}|100)$/.test(e)) {
        return
      } else {
        this.projectManageRatio = e
      }
    },
    noProjManText(text) {
      return text.key !== 'project_manage_text'
    },
    getWidth(w) {
      return this.isPdf ? `16.66%` : w
    },
    closeDrawer() {
      this.$emit('close')
    },

    // 根据版本获取wwc区间表头
    getWwcTableRangeByProduceVersion(version) {
      return wordReportRangeColumn.find((v) => v.produceVersion === version)?.range || []
    },

    // 添加单价
    handleAddPrice({ serviceType, targetCode }) {
      const { sourceCode, cusAgencyId } = this.reportInfo
      const { field, documentType, currency } = this.info
      this.priceVisible = true
      this.priceFormData = {
        agencyId: cusAgencyId,
        currency,
        serviceType,
        targetCode,
        sourceCode,
        field,
        documentType,
      }
    },

    /**
     * 表格数据转换
     * 1 按语言分类 整理数据
     * {
     *   zh-cn: [],
     *   en-us: []
     * }
     * 2 转换成真实数据
     * [
     *  {},
     *  {}
     * ]
     * */
    getWwcTableSource(data) {
      // 当只有一个单子的时候暂时用targetcode匹配多个用orderId匹配
      const langMap = data.reduce((acc, val) => {
        const k = this.ids.length < 2 ? val.targetCode : val.targetCode + '%%' + val.orderId
        if (acc[k]) {
          acc[k] = [...acc[k], val]
        } else {
          acc[k] = [val]
        }
        return acc
      }, {})
      return Object.entries(langMap).map(([lang, data]) => {
        const rangeMap = data.reduce((acc, val) => {
          acc[val.discountRange] = val.baseCount || 0
          return acc
        }, {})
        return {
          targetCode: lang.split('%%')[0],
          id: uuid(),
          ...rangeMap,
        }
      })
    },

    // 批量
    getWwcTableSourceBatch(data) {
      // 当只有一个单子的时候暂时用targetcode匹配多个用orderId匹配
      const langMap = data.reduce((acc, val) => {
        const k = val.sourceCode + '-' + val.targetCode + '%%' + val.orderId
        if (acc[k]) {
          acc[k] = [...acc[k], val]
        } else {
          acc[k] = [val]
        }
        return acc
      }, {})

      return Object.entries(langMap).map(([lang, data]) => {
        const rangeMap = data.reduce((acc, val) => {
          acc['orderCode'] = val.orderCode
          acc[val.discountRange] = val.baseCount || 0
          return acc
        }, {})

        return {
          sourceCode: lang.split('%%')[0].split('-')[0],
          targetCode: lang.split('%%')[0].split('-')[1],
          id: uuid(),
          ...rangeMap,
        }
      })
    },
    // 只用来返回统计的折扣字数
    getWwcTableSourceDiscount(data) {
      const langMap = data.reduce((acc, val) => {
        let k
        if (this.ids.length < 2) {
          k = val.sourceCode + '-' + val.targetCode
        } else {
          k = val.sourceCode + '-' + val.targetCode + '%%' + val.orderId
        }
        if (acc[k]) {
          acc[k] = [...acc[k], val]
        } else {
          acc[k] = [val]
        }
        return acc
      }, {})
      return Object.entries(langMap).map(([lang, data]) => {
        const rangeMap = data.reduce((acc, val) => {
          acc['orderCode'] = val.orderCode
          acc[val.discountRange] = val.discountCount || 0
          return acc
        }, {})
        return {
          sourceCode: lang.split('%%')[0].split('-')[0],
          targetCode: lang.split('%%')[0].split('-')[1],
          id: uuid(),
          ...rangeMap,
        }
      })
    },

    // 价格统计
    getPriceByAmount(index) {
      // 通过 priceType 计算
      if (this.info.status === 1) {
        const { priceType, selectivePriceList, amount = 0 } = this.dataSource[index]
        if (priceType && selectivePriceList && selectivePriceList.length) {
          const { price = 0 } = selectivePriceList.find((v) => v.id === priceType)
          // return numeral(price * amount).format('0.[00]')
          return numeral(price).multiply(amount).value()
        } else {
          return null
        }
      } else {
        // 直接计算
        const { price = 0, amount = 0 } = this.dataSource[index]
        // return numeral(price * amount).format('0.[00]')
        return numeral(price).multiply(amount).value()
      }
    },

    // table footer 价格合计
    getTableTotalPrice() {
      return numeral(
        this.dataSource.reduce((acc, val) => {
          // 通过 priceType 计算
          if (this.info.status === 1) {
            const { selectivePriceList, priceType, amount = 0 } = val
            if (priceType && selectivePriceList && selectivePriceList.length) {
              const { price = 0 } = selectivePriceList.find((v) => v.id === priceType)
              // acc = acc + Number(numeral(price * amount).format('0.[00]') || 0)
              acc = numeral(acc).add(numeral(price).multiply(amount).value()).value()
            }
          } else {
            const { price = 0, amount = 0 } = val
            // acc = acc + Number(numeral(price * amount).format('0.[00]') || 0)
            acc = numeral(acc).add(numeral(price).multiply(amount).value()).value()
          }
          return acc
        }, 0)
      ).value()
    },

    // table footer 价格合计（带项目管理费）
    getTableTotalPrice2() {
      return numeral(this.getTableTotalPrice()).add(this.proAdminCost).value()
    },

    // batch footer 价格合计
    getBatchDataSourceTotalPrice() {
      return numeral(
        this.batchDataSource.reduce((acc, val) => {
          acc = numeral(acc).add(val.quotePrice).value()
          return acc
        }, 0)
      ).format('0.[00]')
    },

    // 币种单位
    currencyUnit(currency) {
      if (currency || this.$is.Defined(this.info.currency) || this.$is.Defined(this.reportInfo.currency)) {
        const unit =
          this.$store.getters['app/getDictLabel'](
            'CURRENCY',
            currency || this.info.currency || this.reportInfo.currency
          ) || ''
        return unit.split(';')
      } else {
        return false
      }
    },

    // 统计wwc
    getWwcCount(idx) {
      const count = this.wwcColumn.reduce((acc, key) => {
        acc = acc + (Number(this.wwcDataSourceDiscount[idx][key]) || 0)
        return acc
      }, 0)
      return numeral(count).format('0.[00]')
    },

    // 批量版本统计wwc
    getBatchWwcCount(version, discount, idx) {
      const wwcColumn = this.getWwcTableRangeByProduceVersion(version)
      const count = wwcColumn.reduce((acc, key) => {
        acc = acc + (Number(discount[idx][key]) || 0)
        return acc
      }, 0)
      return numeral(count).format('0.[00]')
    },

    // 新增报价项
    handleAddPriceItem(item) {
      this.dataSource.push(item)
    },

    // 删除报价项
    handeDeletePriceItem(idx) {
      this.dataSource.splice(idx, 1)
    },

    // 添加完报价项后刷新下拉项
    async handleRefreshSelectItem() {
      try {
        this.loading = true
        const info = await this.$http({
          key: 'orderProcessQuoteInfo',
          params: { id: this.info.id },
        })
        const { priceList } = info

        priceList.forEach((v) => {
          this.dataSource.forEach((d) => {
            if (d.id === v.id) {
              d.selectivePriceList = v.selectivePriceList
            }
          })
        })

        this.loading = false
      } catch (err) {
        this.loading = false
        this.$httpNotify(err)
      }
    },

    // 获取单条报价单
    // async getReportInfo(id) {
    //   try {
    //     this.loading = true
    //     const info = await this.$http({
    //       key: 'orderProcessQuoteInfo',
    //       params: { id },
    //     })
    //     console.log('石头报价单信息', info)

    //     this.loading = false
    //     this.loading = false
    //     this.reportInfo = info
    //     this.wwcDataSource = this.getWwcTableSource(this.reportInfo.wwcList)
    //     this.wwcDataSourceDiscount = this.getWwcTableSourceDiscount(this.reportInfo.wwcList)
    //     this.dataSource = info.priceList.map((v) => ({
    //       ...v,
    //       priceType: v.priceType || undefined,
    //     }))

    //     const wordColumn = wordReportRangeColumn.find((item) => {
    //       return item.produceVersion === (this.info?.produceVersion || info.produceVersion)
    //     })
    //     this.wwcColumn = wordColumn?.range || []
    //   } catch (err) {
    //     this.loading = false
    //     this.$httpNotify(err)
    //   }
    // },

    // 石头获取报价单
    async getStoneReportInfo(id) {
      console.log(3)
      try {
        this.loading = true
        const info = await this.$http({
          key: 'stoneQuoteInfo',
          params: { id },
        })
        console.log('石头报价单信息', info)

        // 如果有待确认的报价信息  刚批准报价时肯定没有 kkkkk
        if (info.quoteNeedApprove == 1) {
          this.actuallyPrice = info.quotePendingPrice
        } else {
          this.actuallyPrice = info.quoteRealPrice
        }

        // 如果供应商在此把价格改了，那么客户端那里就要显示接受或拒绝按钮
        if (info.quoteNeedApprove == 1) {
          this.showConfirmBtn = true
        } else {
          this.showConfirmBtn = false
        }

        this.loading = false
        this.loading = false
        this.reportInfo = info
        if (this.reportInfo.wwcList == null) {
          this.reportInfo.wwcList = []
        }

        this.wwcDataSource = this.getWwcTableSource(this.reportInfo.wwcList)
        this.wwcDataSourceDiscount = this.getWwcTableSourceDiscount(this.reportInfo.wwcList)

        this.dataSource = info.priceList.map((v) => ({
          ...v,
          priceType: v.priceType || undefined,
        }))

        const wordColumn = wordReportRangeColumn.find((item) => {
          return item.produceVersion === (this.info?.produceVersion || info.produceVersion)
        })
        this.wwcColumn = wordColumn?.range || []
      } catch (err) {
        console.log('错误', err)
        this.loading = false
        this.$httpNotify(err)
      }
    },

    // 获取批量报价单
    async getBatchReportInfo(ids) {
      try {
        this.loading = true
        const info = await this.$http({
          key: 'orderProcessQuoteBatchInfo',
          params: {
            ids: ids.join(','),
          },
        })
        this.loading = false
        this.reportInfo = info

        const { wwcList, orderList } = info
        // 给wwc添加version
        const wwcListWithVersion = wwcList.map((v) => ({
          ...v,
          produceVersion: orderList.find((o) => o.id === v.orderId)?.produceVersion,
        }))

        const wwcListA = wwcListWithVersion.filter((v) => v.produceVersion === 1)
        const wwcListB = wwcListWithVersion.filter((v) => v.produceVersion === 2)

        this.wwcDataSourceVersionA = this.getWwcTableSourceBatch(wwcListA)
        this.wwcDataSourceDiscountA = this.getWwcTableSourceDiscount(wwcListA)

        this.wwcDataSourceVersionB = this.getWwcTableSourceBatch(wwcListB)
        this.wwcDataSourceDiscountB = this.getWwcTableSourceDiscount(wwcListB)

        this.batchDataSource = info.orderList
      } catch (err) {
        this.loading = false
        this.$httpNotify(err)
      }
    },

    // 创建报价
    async handleCreateQuote() {
      console.log(this.quoteRealPrice)
      console.log(this.quoteNote)
      console.log('dataSource', this.dataSource)
      try {
        this.loading = true
        let info = await this.$http('stoneQuote', {
          id: this.info.id,
          containsProjectManageCost: this.containsProjectManageCost,
          projectManageRatio: this.projectManageRatio,
          quoteRealPrice: this.quoteRealPrice, // 新增实际订单报价 非必须 不传
          quoteNote: this.quoteNote, // 新增报价说明
          priceList: this.dataSource
            .filter((v) => v.key !== 'project_manage_text')
            .map((v) => ({
              amount: v.amount,
              id: v.new ? undefined : v.id,
              serviceType: v.serviceType,
              priceType: v.priceType,
            })),
        })
        console.log('提交', info)
        this.loading = false
        // 刷新订单info
        this.$bus.$emit('_refresh_order_info_')
        // 关闭抽屉
        this.$emit('close')
      } catch (err) {
        console.log('错误', err)
        this.loading = false
        this.$httpNotify(err)
      }
    },

    // 拒绝报价
    handleRefuse() {
      this.$confirm({
        title: this.$t('orderInfo.quote_confirm_title'),
        icon: (h) => h(Icon, { props: { type: 'exclamation-circle', theme: 'filled' } }),
        content: this.$t('orderInfo.quote_confirm_info'),
        okText: this.$t('orderInfo.quote_confirm_cancelText'),
        cancelText: this.$t('orderInfo.quote_confirm_okText'),
        onCancel: async () => {
          try {
            await this.$http('orderProcessWithdraw', { id: this.info.id })
            // 刷新订单info
            this.$bus.$emit('_refresh_order_info_')
            // 关闭抽屉
            this.$emit('close')
          } catch (err) {
            this.$httpNotify(err)
          }
        },
      })
    },

    // 退回重新报价
    handleReject() {
      this.$confirm({
        title: this.$t('orderInfo.quote_reject_title'),
        icon: (h) => h(Icon, { props: { type: 'exclamation-circle', theme: 'filled' } }),
        content: this.$t('orderInfo.quote_reject_info'),
        okText: this.$t('orderInfo.quote_reject_cancelText'),
        cancelText: this.$t('orderInfo.quote_reject_okText'),
        onCancel: async () => {
          try {
            await this.$http('stoneRejectQuotation', { id: this.info.id })
            // 刷新订单info
            this.$bus.$emit('_refresh_order_info_')
            // 关闭抽屉
            this.$emit('close')
          } catch (err) {
            this.$httpNotify(err)
          }
        },
      })
    },

    // 批准报价
    async handleApprove() {
      this.approveVisible = true
      // 批准报价的时候调一下接受价格的接口，否则在批准报价后客户仍然能拒接报价前修改的价格
      let accept = await this.$http('stoneExamineRealPrice', {
        id: this.info.id,
        passOrNot: 1,
      })
      console.log('接受', accept)
      this.showConfirmBtn = false
    },

    // 新增报价选项
    handleQuoteOption() {
      this.quoteOptionVisible = true
    },

    // 导出报价单
    async handleExport() {
      try {
        let name
        if (this.ids.length <= 1) {
          name = `${this.info.name}_${this.$t('orderInfo.quote')}`
        } else {
          name = `${this.info.cusAgencyName}_${this.$t('orderInfo.quote')}_${moment().format('YYYYMMDDHHmm')}`
        }
        this.pdfLoading = true
        const pdf = await this.$http(
          'ossCmdHtmlToPdf',
          {
            oriUrl: 'pdf-quotation',
            token: Cookies.get(storageKey.TOKEN),
            pdfInfoList: [{ ids: this.info.id ? this.info.id : this.ids.join(','), pdfName: '' }],
          },
          {
            responseType: 'blob',
          }
        )
        this.pdfLoading = false
        name = name + `.pdf`
        downloadBlob(name, 'application/pdf;charset-UTF-8', pdf)
      } catch (err) {
        this.pdfLoading = false
        this.$httpNotify(err)
      }
    },

    async func() {
      try {
        const params = {}
        // eslint-disable-next-line no-unused-vars
        const data = await this.$http('name', params)
        console.log(`data`, data)
      } catch (err) {
        this.$httpNotify(err)
      }
    },

    // 新增 在生产中 再次修改价格
    showIsModify() {
      this.isModify = true
    },

    async againModify() {
      if (this.againModifyPrice !== '') {
        this.actuallyPrice = this.againModifyPrice
        // 供应商 再次修改金额
        let modify = await this.$http('stoneModifyRealPrice', {
          id: this.info.id,
          quoteRealPrice: this.againModifyPrice,
        })
        console.log('修改金额', modify)
      }
      this.isModify = false
    },

    cancelModify() {
      this.isModify = false
      this.againModifyPrice = ''
    },

    async accept() {
      let accept = await this.$http('stoneExamineRealPrice', {
        id: this.info.id,
        passOrNot: 1,
      })
      console.log('接受', accept)
      this.showConfirmBtn = false
    },

    async refuse() {
      let refuse = await this.$http('stoneExamineRealPrice', {
        id: this.info.id,
        passOrNot: 0,
      })
      console.log('拒绝', refuse)
      this.actuallyPrice = this.reportInfo.quoteRealPrice
      this.showConfirmBtn = false
    },

    // 上传pdf
    async uppdf(data) {
      this.showIcon = false
      console.log('上传pdf', data)
      if (data.length > 1) {
        data.shift()
        let uppdf = await this.$http('stoneUploadQuoteFile', {
          orderId: this.info.id,
          name: data[0].name, // 文件名
          size: data[0].size,
          url: data[0].url,
        })
        console.log('上传pdf结果', uppdf)
        this.showIcon = true
        if (uppdf) {
          this.showDownloadPDF = true
        }
      } else {
        console.log('上传pdf', data[0])
        let uppdf = await this.$http('stoneUploadQuoteFile', {
          orderId: this.info.id,
          name: data[0].name, // 文件名
          size: data[0].size,
          url: data[0].url,
        })
        console.log('上传pdf结果', uppdf)
        this.showIcon = true
        if (uppdf) {
          this.showDownloadPDF = true
        }
      }
    },

    // 下载pdf
    async downpdf() {
      let xiazai = await this.$http({
        key: 'stoneGetQuoteFile',
        params: {
          id: this.info.id,
        },
      })
      downloadUrl(xiazai.url)
      console.log('下载', xiazai.url)
    },

    // 判断一下下载按钮是否显示
    async showDownloadBtn() {
      let xiazai = await this.$http({
        key: 'stoneGetQuoteFile',
        params: {
          id: this.info.id,
        },
      })
      if (xiazai.url) {
        this.showDownloadPDF = true
      } else {
        this.showDownloadPDF = false
      }
    },

    // jiaoyan(e) {
    // console.log(e.target.value)
    // },
    settlementPriceChange1({ target }) {
      const handledTargetVal = this.validateInput(target).value
      console.log('输入框金额', handledTargetVal)
      this.quoteRealPrice = handledTargetVal
    },

    settlementPriceChange({ target }) {
      const handledTargetVal = this.validateInput(target).value
      console.log('输入框金额', handledTargetVal)
      this.againModifyPrice = handledTargetVal
    },

    validateInput(target) {
      let val = target.value
      try {
        val = val.replace(/[^\d.]/g, '') //清除“数字”和“.”以外的字符
        val = val.replace(/\.{2,}/g, '.') //只保留第一个. 清除多余的
        val = val.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3') //只能输入两个小数
        if (val.indexOf('.') < 0 && val != '') {
          //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
          val = parseFloat(val)
        }
        // 首字符不能是小数点
        if (val[0] === '.') {
          val = ''
        }
        target.value = val
        return target
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>
